export default {
    methods: {
        printData(data, fileName, noPrint) {
            let allTables = [...document.querySelectorAll(`table`)];
            const cTCols = document.querySelectorAll("table thead");
            const cTdata = document.querySelectorAll("table tbody");
            cTCols.forEach((cols, index) => {
                let colsName = [...cols.rows[0].cells].map((c) => c.textContent.trim());
                let result = [];
                [...cTdata[index].rows].forEach(({cells}) => {
                    let rowData = [...cells].map(({innerHTML: val}) => val?.trim() ?? "");
                    let obj = {};
                    colsName.forEach((key, i) => (obj[key] = rowData[i] ? rowData[i].trim() : "No data available"));
                    result.push(obj);
                });

                // <table id="print-table" class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border tw-border-solid tw-border-gray-300">
                let table = `
                    <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                    ${result
                        .map((values) =>
                            Object.entries(values)
                                .map(([key, val]) => `<tr> <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap"> <div class="tw-flex tw-flex-wrap">${key} </div> </td> <td class="tw-px-6 tw-py-4 "> <div class="tw-flex tw-flex-wrap"> ${val}  </div> </td> </tr>`)
                                .join(" ")
                        )
                        .join(" ")}
                    </tbody> 
                    `;
                // </table>
                allTables[index].style.setProperty("display", "none", "important");

                const node = document.createElement("table");
                node.setAttribute("id", "print-table");
                node.setAttribute("class", "tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border tw-border-solid tw-border-gray-300");
                node.innerHTML = table;
                allTables[index].parentNode.append(node);
            });

            let printContents = document.getElementById(data).innerHTML;
            if (!noPrint) {
                if (document.querySelector('[data-id="zsalesiq"]')) {
                    document.querySelector('[data-id="zsalesiq"]').style.display = "none";
                }

                document.getElementsByTagName("main")[0].style.setProperty("display", "none", "important");
                let elemDiv = document.createElement("div");
                elemDiv.setAttribute("id", "appendChild");
                elemDiv.innerHTML = printContents;
                elemDiv.innerHTML = printContents;
                document.body.appendChild(elemDiv);
                let nameFile = window.document.title;
                if (fileName) {
                    window.document.title = fileName;
                }
                window.focus();
                window.print();

                let currTables = [...document.querySelectorAll(`table`)];

                currTables.forEach((data, index) => {
                    if (data.id === "print-table") {
                        currTables[index].remove();
                    } else {
                        currTables[index].style.display = "table";
                    }
                });

                document.getElementsByTagName("main")[0].removeAttribute("style");
                if (document.querySelector('[data-id="zsalesiq"]')) {
                    document.querySelector('[data-id="zsalesiq"]').style.display = "block";
                }
                // if(document.querySelector('.tw-overflow-auto')) {
                //   document.querySelector('tw-overflow-auto').style.overflow = 'auto'
                // }
                document.getElementById("appendChild").remove();
                window.document.title = nameFile;
            } else {
                return printContents;
            }
        },
    },
};
