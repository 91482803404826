export default {
    props: {
        differentials: {
            type: Object,
            default: null,
        },
    },
    methods: {
        highlightChanges() {
            // Highlight data level changes
            const blankCells = document.querySelectorAll(".blank_row");
            blankCells.forEach((cell) => {
                cell.parentElement.remove();
            });

            if (this.differentials && (this.differentials.data || this.differentials.details)) {
                for (const type in this.differentials.data) {
                    this.differentials?.data?.[type]?.forEach((identifier) => {
                        let elements;
                        let otherTableBody = null;
                        let lastInsertionIndex = -1;
                        if (type === "iterable_item_added" && this.$route.params.toolName !== "news-tool") elements = document.querySelectorAll(`.monitoring-differential-highlighter #version-card-2 [identifier*="['data']${identifier}"]`);
                        else if (type === "iterable_item_removed" && this.$route.params.toolName !== "news-tool") elements = document.querySelectorAll(`.monitoring-differential-highlighter #version-card-1 [identifier*="['data']${identifier}"]`);
                        else elements = document.querySelectorAll(`.monitoring-differential-highlighter [identifier*="['data']${identifier}"]`);
                        elements.forEach((ele) => {
                            ele.className += " cascading-highlighter";
                            const row = ele.closest("tr");
                            const index = row?.parentElement ? Array.prototype.indexOf.call(row.parentElement.children, row) : -1;
                            const table = ele.closest("table");
                            const headingBlockSibling = ele.closest(".headingBlock-sibling");
                            let headingBlock;
                            if (headingBlockSibling?.previousElementSibling?.classList?.value?.includes("headingBlock ")) headingBlock = headingBlockSibling.previousElementSibling;
                            if (row && !row.classList?.value?.includes("cascading-highlighter")) row.className += " cascading-highlighter";
                            if (headingBlock && !headingBlock.classList?.value?.includes("cascading-highlighter")) headingBlock.className += " cascading-highlighter";

                            if (type === "iterable_item_added" && index > lastInsertionIndex && this.$route.params.toolName !== "news-tool")
                                otherTableBody = document
                                    .querySelectorAll(`.monitoring-differential-highlighter #version-card-1`)[0]
                                    .getElementsByClassName(table?.classList.value)[0]
                                    ?.getElementsByTagName("tbody")[0];
                            else if (type === "iterable_item_removed" && index > lastInsertionIndex && this.$route.params.toolName !== "news-tool")
                                otherTableBody = document
                                    .querySelectorAll(`.monitoring-differential-highlighter #version-card-2`)[0]
                                    .getElementsByClassName(table?.classList.value)[0]
                                    ?.getElementsByTagName("tbody")[0];

                            if ((type === "iterable_item_added" || type === "iterable_item_removed") && index > lastInsertionIndex && this.$route.params.toolName !== "news-tool") {
                                let collection = otherTableBody?.children;
                                if (collection?.[index - 1]) {
                                    let added = collection?.[index - 1].parentNode.insertRow(index);
                                    added.outerHTML = `<td colspan="${row.childElementCount}" class="blank_row ${row.children[0].className}" style="background-color: #F6F1BB; height: ${row.children[0].offsetHeight}px"> </td>`;
                                    lastInsertionIndex = index;
                                }
                            }
                        });
                    });
                }

                for (const type in this.differentials.details) {
                    this.differentials?.details?.[type]?.forEach((identifier) => {
                        let elements;
                        let otherTableBody = null;
                        let lastInsertionIndex = -1;
                        if (type === "iterable_item_added" && this.$route.params.toolName !== "news-tool") elements = document.querySelectorAll(`.monitoring-differential-highlighter #version-card-2 [identifier*="['details']${identifier}"]`);
                        else if (type === "iterable_item_removed" && this.$route.params.toolName !== "news-tool") elements = document.querySelectorAll(`.monitoring-differential-highlighter #version-card-1 [identifier*="['details']${identifier}"]`);
                        else elements = document.querySelectorAll(`.monitoring-differential-highlighter [identifier*="['details']${identifier}"]`);
                        elements.forEach((ele) => {
                            ele.className += " cascading-highlighter";
                            const row = ele.closest("tr");
                            const index = row?.parentElement ? Array.prototype.indexOf.call(row.parentElement.children, row) : -1;
                            const table = ele.closest("table");
                            const headingBlockSibling = ele.closest(".headingBlock-sibling");
                            let headingBlock;
                            if (headingBlockSibling?.previousElementSibling?.classList?.value?.includes("headingBlock ")) headingBlock = headingBlockSibling.previousElementSibling;
                            if (row && !row.classList?.value?.includes("cascading-highlighter")) row.className += " cascading-highlighter";
                            if (headingBlock && !headingBlock.classList?.value?.includes("cascading-highlighter")) headingBlock.className += " cascading-highlighter";

                            if (type === "iterable_item_added" && index > lastInsertionIndex && this.$route.params.toolName !== "news-tool")
                                otherTableBody = document
                                    .querySelectorAll(`.monitoring-differential-highlighter #version-card-1`)[0]
                                    .getElementsByClassName(table?.classList.value)[0]
                                    ?.getElementsByTagName("tbody")[0];
                            else if (type === "iterable_item_removed" && index > lastInsertionIndex && this.$route.params.toolName !== "news-tool")
                                otherTableBody = document
                                    .querySelectorAll(`.monitoring-differential-highlighter #version-card-2`)[0]
                                    .getElementsByClassName(table?.classList.value)[0]
                                    ?.getElementsByTagName("tbody")[0];

                            if ((type === "iterable_item_added" || type === "iterable_item_removed") && index > lastInsertionIndex && this.$route.params.toolName !== "news-tool") {
                                let collection = otherTableBody?.children;
                                if (collection?.[index - 1]) {
                                    let added = collection?.[index - 1].parentNode.insertRow(index);
                                    added.outerHTML = `<td colspan="${row.childElementCount}" class="blank_row ${row.children[0].className}" style="background-color: #F6F1BB; height: ${row.children[0].offsetHeight}px"> </td>`;
                                    lastInsertionIndex = index;
                                }
                            }
                        });
                    });
                }

                // add event listeners on scroll of tables (to sync scroll at both versions)
                const tables = document.querySelectorAll(".monitoring-differential-highlighter table");
                tables.forEach((table) => {
                    const parent = table?.closest(".print-overflow-table");
                    parent?.addEventListener("scroll", function() {
                        const left = parent.scrollLeft;
                        const tableClass = parent?.className;
                        const similarTables = document.querySelectorAll(".monitoring-differential-highlighter")[0].getElementsByClassName(tableClass);
                        for (let i = 0; i < similarTables.length; i++) {
                            const tbl = similarTables[i];
                            if (tbl) tbl.scrollLeft = left;
                        }
                    });
                });

                const v1sectionsData = document.querySelectorAll(".monitoring-differential-highlighter #version-card-1 .headingBlock-sibling");
                const v2sectionsData = document.querySelectorAll(".monitoring-differential-highlighter #version-card-2 .headingBlock-sibling");
                v1sectionsData.forEach((section, index) => {
                    let height1 = v1sectionsData[index]?.offsetHeight;
                    let height2 = v2sectionsData[index]?.offsetHeight;
                    if (v1sectionsData[index]?.style) v1sectionsData[index].style.minHeight = Math.max(height1, height2).toString() + "px";
                    if (v2sectionsData[index]?.style) v2sectionsData[index].style.minHeight = Math.max(height1, height2).toString() + "px";
                });
            }
        },
    },
};
